<template>
  <div class="dropdown ms-2">
    <a href="#" data-bs-toggle="dropdown" id="dropdown_profile">
      <img
        :src="userAvatar"
        class="img-radius p-1 header-profile-pict"
        alt="User-Profile-Image"
      />
    </a>
    <div class="dropdown-menu dropdown-menu-end profile-notification">
      <div class="pro-status">
        <h5>{{ accountType }} Account</h5>
        <p>{{ formatActivePeriod }}</p>
      </div>
      <ul class="pro-body mb-0">
        <li>
          <router-link to="/profile" class="dropdown-item" id="btn_profile">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              class="me-2"
            >
              <path
                d="M12.12 12.7805C12.05 12.7705 11.96 12.7705 11.88 12.7805C10.12 12.7205 8.71997 11.2805 8.71997 9.51047C8.71997 7.70047 10.18 6.23047 12 6.23047C13.81 6.23047 15.28 7.70047 15.28 9.51047C15.27 11.2805 13.88 12.7205 12.12 12.7805Z"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M18.74 19.3796C16.96 21.0096 14.6 21.9996 12 21.9996C9.40001 21.9996 7.04001 21.0096 5.26001 19.3796C5.36001 18.4396 5.96001 17.5196 7.03001 16.7996C9.77001 14.9796 14.25 14.9796 16.97 16.7996C18.04 17.5196 18.64 18.4396 18.74 19.3796Z"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            {{ $t('message.profile') }}</router-link
          >
        </li>
        <li>
          <a
            href="https://api.whatsapp.com/send?phone=08111599333"
            class="dropdown-item"
            target="_blank"
            id="btn_help_center"
          >
            <svg
              fill="none"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              class="me-2"
            >
              <path
                d="M5 10H4C2.89543 10 2 10.8954 2 12V14C2 15.1046 2.89543 16 4 16H4.5C4.77614 16 5 15.7773 5 15.5012C5 13.9679 5 11.1395 5 9C5 6 7.5 3 12 3C16.5 3 19 6 19 9V16H20C21.1046 16 22 15.1046 22 14V12C22 10.8954 21.1046 10 20 10H19"
                stroke="currentColor"
                stroke-width="1.5"
              />
              <path
                d="M19 16C19 19.5 17.5 21 14 21"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                d="M9 15.5C10.125 18 13.875 18 15 15.5"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                d="M14 22H14.5C15.0523 22 15.5 21.5523 15.5 21V20.5C15.5 19.9477 15.0523 19.5 14.5 19.5H14C13.4477 19.5 13 19.9477 13 20.5V21C13 21.5523 13.4477 22 14 22Z"
                fill="currentColor"
              />
            </svg>
            {{ $t('message.helpCenter') }}
          </a>
        </li>
        <li>
          <a
            href="javascript:void(0)"
            @click="logout"
            class="dropdown-item"
            id="btn_logout"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              class="me-2"
            >
              <path
                d="M15.24 22.2705H15.11C10.67 22.2705 8.53002 20.5205 8.16002 16.6005C8.12002 16.1905 8.42002 15.8205 8.84002 15.7805C9.24002 15.7405 9.62002 16.0505 9.66002 16.4605C9.95002 19.6005 11.43 20.7705 15.12 20.7705H15.25C19.32 20.7705 20.76 19.3305 20.76 15.2605V8.74047C20.76 4.67047 19.32 3.23047 15.25 3.23047H15.12C11.41 3.23047 9.93002 4.42047 9.66002 7.62047C9.61002 8.03047 9.26002 8.34047 8.84002 8.30047C8.42002 8.27047 8.12001 7.90047 8.15001 7.49047C8.49001 3.51047 10.64 1.73047 15.11 1.73047H15.24C20.15 1.73047 22.25 3.83047 22.25 8.74047V15.2605C22.25 20.1705 20.15 22.2705 15.24 22.2705Z"
                fill="currentColor"
              />
              <path
                d="M15.0001 12.75H3.62012C3.21012 12.75 2.87012 12.41 2.87012 12C2.87012 11.59 3.21012 11.25 3.62012 11.25H15.0001C15.4101 11.25 15.7501 11.59 15.7501 12C15.7501 12.41 15.4101 12.75 15.0001 12.75Z"
                fill="currentColor"
              />
              <path
                d="M5.84994 16.0998C5.65994 16.0998 5.46994 16.0298 5.31994 15.8798L1.96994 12.5298C1.67994 12.2398 1.67994 11.7598 1.96994 11.4698L5.31994 8.11984C5.60994 7.82984 6.08994 7.82984 6.37994 8.11984C6.66994 8.40984 6.66994 8.88984 6.37994 9.17984L3.55994 11.9998L6.37994 14.8198C6.66994 15.1098 6.66994 15.5898 6.37994 15.8798C6.23994 16.0298 6.03994 16.0998 5.84994 16.0998Z"
                fill="currentColor"
              />
            </svg>
            Logout
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import AvatarDefault from '@/assets/images/user/olle.png';
import Cookies from 'js-cookie';
import { mapGetters } from 'vuex';
import dayjs from 'dayjs';
import 'dayjs/locale/id';

export default {
  name: 'ProfileComponent',
  data: function () {
    return {
      avatarDefault: AvatarDefault,
      accountPeriod: '',
      accountType: '',
    };
  },
  computed: {
    ...mapGetters(['get_user_data']),
    userAvatar() {
      if (
        this.get_user_data.photo &&
        this.get_user_data.photo.match(
          /^https:\/\/cdn\.qollega\.(id|com)\/.*\/uploads\/avatar/
        )
      ) {
        const imageUrl = this.get_user_data.photo;
        return imageUrl;
      } else {
        return this.avatarDefault;
      }
    },
    formatActivePeriod() {
      if (this.accountPeriod) {
        return (
          'Masa aktif sampai : ' +
          dayjs(this.accountPeriod).locale('id').format('DD MMMM YYYY')
        );
      }
      return 'Masa aktif telah kadaluarsa';
    },
  },
  methods: {
    async logout() {
      try {
        await this.Axios.post(`${process.env.VUE_APP_API_URL}logout`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        Cookies.remove('user_data');
        localStorage.clear();
        window.location.href = '/login';
      } catch (e) {
        console.error(e);
      }
    },
  },
  created: function () {
    setTimeout(() => {
      let warehouseData = JSON.parse(localStorage.getItem('current_warehouse'));

      this.accountPeriod = warehouseData?.expired_date;
      this.accountType =
        warehouseData?.type == 'trial' ? 'Free' : 'Subscription';
    }, 1500);
  },
};
</script>

<style scoped>
.header-profile-pict {
  width: 50px;
  height: 50px;
  object-fit: cover;
}
</style>
