<template>
  <div id="app">
    <div
      v-if="
        currentRoute !== 'not_found' &&
        currentRoute !== 'subscription_success' &&
        currentRoute !== 'subscription_invoice'
      "
    >
      <Sidenav :is_sidebar_open="isSidebarOpen" />
      <Header @toggleSidebar="(value) => (isSidebarOpen = value)" />
      <div class="pcoded-main-container overflow-hidden">
        <div class="pcoded-content">
          <div class="main-body">
            <router-view v-if="isOnline"></router-view>
            <OfflineComponent v-else />
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import Header from './components/Header.vue';
import Sidenav from './components/Sidenav.vue';
import OfflineComponent from '@/global_components/Offline.vue';
import { mapMutations, mapGetters } from 'vuex';

export default {
  name: 'App',
  metaInfo: {
    title: 'Demo Admin',
  },
  data: function () {
    return {
      isOnline: true,
      isSidebarOpen: false,
      userData: undefined,
    };
  },
  components: {
    Header,
    Sidenav,
    OfflineComponent,
  },
  computed: {
    ...mapGetters(['get_user_data']),
    currentRoute() {
      return this.$route.name;
    },
  },
  beforeMount: function () {
    this.set_user_data(JSON.parse(localStorage.getItem('user_data')));
  },
  methods: {
    ...mapMutations(['set_user_data', 'set_detail_dashboard']),
    check_connection() {
      this.isOnline = navigator.onLine;
      if (!navigator.onLine) {
        this.$toast.warning('Sedang dalam mode offline');
        return;
      }
    },
    async get_data() {
      try {
        const req = await this.Axios.get(
          `${process.env.VUE_APP_API_URL}beranda/home`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        );
        const res = req.data;
        this.set_detail_dashboard(res);
      } catch (e) {
        console.error(e);
        this.$toast.error('Maaf terjadi kesalahan saat memuat data');
        window.location.href = '/login';
      }
    },
    check_status() {
      let statusAccount = JSON.parse(
        localStorage.getItem('account_status')
      )?.status;

      this.$router.beforeEach((to, from, next) => {
        if (statusAccount !== 'Akun Kadaluarsa') {
          next();
        } else if (
          to.name === 'pembelian_akun' ||
          to.name === 'masa_aktif' ||
          to.name === 'subscription_success' ||
          to.name === 'subscription_invoice'
        ) {
          next();
        } else {
          next({ name: 'masa_aktif' });
        }
      });
    },
  },
  mounted: function () {
    this.check_connection();

    if (!localStorage.getItem('user_data') || !localStorage.getItem('token')) {
      window.location.href = '/login';
    } else {
      if (this.get_user_data.role_name === 'Kasir') {
        window.location.href = '/pos';
      } else {
        setTimeout(() => {
          this.check_status();
        }, 1500);
      }
    }
  },
  created: function () {
    window.addEventListener('online', this.check_connection);
    window.addEventListener('offline', this.check_connection);

    if (!localStorage.getItem('warehouse_id')) {
      window.location.href = '/login';
    } else {
      this.get_data();
    }
  },
};
</script>
