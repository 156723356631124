<template>
  <header
    class="navbar pcoded-header navbar-expand-lg navbar-light headerpos-fixed header-light"
    style="z-index: 1050"
  >
    <div class="m-header">
      <a
        class="mobile-menu"
        id="mobile-collapse"
        href="javascript:void(0)"
        @click="toggleSidebar"
      >
        <span></span>
      </a>

      <a href="/" class="b-brand">
        <img :src="logo" class="img-fluid" style="width: 100px" />
      </a>

      <div class="pcoded-header m-header item-right d-md-none">
        <Notification />
        <Profile />
      </div>
    </div>

    <div class="collapse navbar-collapse">
      <div class="saldo-section d-md-flex d-none">
        <img src="@/assets/icon/wallet.svg" class="img-fluid" />
        <div class="d-flex flex-column saldo-content">
          <p class="text-black m-0">Saldo</p>
          <span class="text-black">
            Rp.
            {{
              new Intl.NumberFormat('id').format(
                this.detailDashboard.saldo || 0
              )
            }}
          </span>
        </div>
      </div>

      <div class="saldo-section d-md-none d-block">
        <div class="d-flex align-items-center mb-1">
          <img src="@/assets/icon/wallet.svg" width="18" class="img-fluid" />
          <p class="text-black m-0">Saldo</p>
        </div>
        <p class="text-black m-0">
          Rp.
          {{
            new Intl.NumberFormat('id').format(this.detailDashboard.saldo || 0)
          }}
        </p>
      </div>

      <ul class="navbar-nav ms-auto d-flex flex-row align-items-center">
        <li v-if="get_user_data.role_name == 'Owner'">
          <DropdownWarehouse />
        </li>
        <li class="d-none d-md-block">
          <a
            class="btn icon me-3"
            href="javascript:void(0)"
            onclick="javascript:toggleFullScreen()"
          >
            <img
              src="@/assets/icon/maximize-screen.svg"
              alt="Maximize Screen"
              height="22"
            />
          </a>
        </li>
        <li class="d-none d-md-block">
          <Notification />
        </li>
        <li class="d-none d-md-block">
          <div>
            <div class="text-dark f-w-600">
              {{ get_user_data.business_name }}
            </div>
            <div class="text-dark f-w-400 f-12">
              {{ get_user_data.role_name }}
            </div>
          </div>
        </li>
        <li class="d-none d-md-block">
          <Profile />
        </li>
      </ul>
    </div>
  </header>
</template>

<script>
import Logo from '@/assets/images/qollega_logo_white.png';
import Profile from './Profile.vue';
import Notification from './Notification.vue';
import DropdownWarehouse from './DropdownWarehouse.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'HeaderComponent',
  data: function () {
    return {
      logo: Logo,
      isSidebarOpen: false,
      isLoading: false,
      saldo: 0,
    };
  },
  components: {
    Profile,
    Notification,
    DropdownWarehouse,
  },
  computed: {
    ...mapGetters(['get_user_data', 'detailDashboard']),
  },
  methods: {
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
      this.$emit('toggleSidebar', this.isSidebarOpen);
    },
  },
  mounted() {
    var acc = document.getElementsByClassName('accordion');
    var i;

    for (i = 0; i < acc.length; i++) {
      acc[i].addEventListener('click', function () {
        this.classList.toggle('active');
        var panel = this.nextElementSibling;
        if (panel.style.maxHeight) {
          panel.style.maxHeight = null;
        } else {
          panel.style.maxHeight = panel.scrollHeight + 'px';
        }
      });
    }
  },
  created: function () {
    this.saldo = this.detailDashboard.saldo || 0;
  },
};
</script>

<style scoped>
.item-right {
  display: flex;
  color: white !important;
  align-items: center;
  right: 20px;
  background: transparent;
  justify-content: flex-end;
}

.saldo-section img {
  margin-right: 8px;
}

.saldo-section {
  margin-left: 220px;
}

.saldo-content {
  padding-left: 10px;
  border-radius: 1px;
  border-left: 1px solid #d4d4d8;
}

@media (max-width: 991px) {
  .saldo-section {
    margin-left: 20px;
  }
}

@media (min-width: 991px) and (max-width: 1200px) {
  .saldo-section {
    margin-left: 118px;
  }
}

.icon {
  margin: 0;
  padding: 0;
  transition: 0.25s ease;
}

.icon:hover {
  transform: scale(1.15);
}
</style>
