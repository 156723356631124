<template>
  <div class="dropdown me-3">
    <a
      class="dropdown-toggle btn p-0 notification-button"
      href="javascript:void(0)"
      data-bs-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <img
        src="@/assets/icon/notification-bell.svg"
        alt="Notification Bell"
        class="icon d-md-block d-none"
      />
      <img
        src="@/assets/icon/notification-white.svg"
        alt="Notification Bell"
        class="icon d-md-none d-block"
      />
      <span class="notification-badge" v-show="notifCount > 0">
        {{ notifCount }}
      </span>
    </a>

    <div
      class="dropdown-menu dropdown-menu-end notification pb-2"
      id="dropdown_notif"
      onclick="event.stopPropagation()"
    >
      <div class="noti-head">
        <h6 class="d-inline-block m-b-0">Notifications</h6>
        <div class="float-end">
          <a href="/">
            <i class="icon feather icon-refresh-cw"></i>
          </a>
        </div>
      </div>
      <ul class="noti-body">
        <div>
          <div class="accordion d-flex justify-content-between">
            Stok Habis
            <div v-if="dataNotif && Object.keys(dataNotif).length > 0">
              <span class="text-black-70 me-3">
                {{ dataNotif.stock_habis.length }} Produk
              </span>
              <i class="icon feather icon-arrow-right"></i>
            </div>
            <span
              v-else
              class="spinner-border spinner-border-sm ps-1"
              role="status"
            ></span>
          </div>
          <div
            class="panel"
            v-if="dataNotif && Object.keys(dataNotif).length > 0"
          >
            <div class="content d-flex flex-column">
              <a
                href="javascript:void(0)"
                v-for="(product, index) in dataNotif.stock_habis.slice(0, 5)"
                :key="index"
                @click="goToInventory(product.name)"
              >
                {{ product.name }}
              </a>
              <a
                href="javascript:void(0)"
                v-if="dataNotif.stock_habis.length > 4"
                @click="goToInventory('')"
              >
                Lainnya...
              </a>
            </div>
          </div>
        </div>
        <div>
          <div class="accordion d-flex justify-content-between border-0">
            Pengingat Stok
            <div v-if="dataNotif && Object.keys(dataNotif).length > 0">
              <span class="text-black-70 me-3">
                {{ dataNotif.pengingat_stock.length }} Produk
              </span>
              <i class="icon feather icon-arrow-right"></i>
            </div>
            <span
              v-else
              class="spinner-border spinner-border-sm"
              role="status"
              style="padding-left: 5px"
            ></span>
          </div>
          <div
            class="panel"
            v-if="dataNotif && Object.keys(dataNotif).length > 0"
          >
            <div class="content d-flex flex-column">
              <a
                href="javascript:void(0)"
                v-for="(product, index) in dataNotif.pengingat_stock.slice(
                  0,
                  5
                )"
                :key="index"
                @click="goToInventory(product.name)"
              >
                {{ product.name }}
              </a>
              <a
                href="javascript:void(0)"
                v-if="dataNotif.pengingat_stock.length > 4"
                @click="goToInventory('')"
              >
                Lainnya...
              </a>
            </div>
          </div>
        </div>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  name: 'NotificationComponent',
  data: function () {
    return {
      dataNotif: {},
      notifCount: 0,
    };
  },
  methods: {
    ...mapMutations(['set_search_query']),
    goToInventory(query) {
      if (this.$router) {
        if (this.$route.name === 'list_stok') {
          this.set_search_query(query);
        } else {
          this.set_search_query(query);
          this.$router.push({ name: 'inventory' });
        }
      }
    },
    async get_notification() {
      try {
        const req = await this.Axios.get(
          `${process.env.VUE_APP_API_URL}beranda/notifikasi`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        );
        const res = req.data;
        this.dataNotif = res;

        // Count Notif
        let stokHabis = res.stock_habis.length;
        let pengingatStok = res.pengingat_stock.length;
        this.notifCount = stokHabis + pengingatStok;
      } catch (e) {
        console.error(e);
        this.$toast.error('Maaf terjadi kesalahan saat memuat notifikasi');
      }
    },
  },
  created: function () {
    this.get_notification();
  },
};
</script>

<style scoped>
.notification-button {
  color: white;
  display: inline-block;
  position: relative;
  padding: 2px 5px;
}

.notification-badge {
  background-color: #fa3e3e;
  border-radius: 30px;
  color: white;
  padding: 0.5px 5px;
  font-size: 10px;
  position: absolute;
  top: -4px;
  right: -4px;
}

.accordion {
  background-color: white;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  transition: 0.4s;
  border-bottom: 1px solid #f1f1f1;
}

.accordion button {
  background-color: white;
  border: none;
  outline: none;
  font-size: 15px;
}

.accordion:last-child {
  border-bottom: none;
}

.accordion:hover {
  background-color: #ccc;
}

.panel {
  padding: 0 18px;
  background-color: white;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

.panel a:first-child {
  padding-top: 16px;
}

.panel a:last-child {
  padding-bottom: 16px;
}

.panel a {
  padding: 4px 0;
}

.icon {
  margin: 0;
  padding: 0;
  transition: 0.25s ease;
}

.icon:hover {
  transform: scale(1.15);
}

.content a:hover {
  color: #5d5fef;
}

@media screen and (max-width: 576px) {
  .dropdown-menu.show.dropdown-menu-end:before {
    left: auto !important;
    right: 66px !important;
  }
}
</style>
