<template>
  <div
    class="dropdown dropdown-toggle dropdown-warehouse me-2"
    v-if="warehouses"
  >
    <a
      href="javascript:void(0)"
      data-bs-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      id="dropdown_warehouse"
      class="stretched-lg-link f-w-500 m-0 p-0 text-black"
    >
      <img src="@/assets/icon/toko.svg" alt="Pilih Cabang" class="mx-1" />
      {{ selectedWarehouse?.nama }}
      <img
        src="@/assets/icon/arrow-down.svg"
        alt="Icon Arrow Down"
        class="ms-2"
      />
    </a>
    <div
      class="dropdown-menu dropdown-menu-end notification warehouses"
      id="warehouse_list"
      onclick="event.stopPropagation()"
    >
      <div class="d-flex flex-column">
        <div
          v-for="warehouse in warehouses"
          :key="warehouse.id"
          class="warehouse-item"
          @click="submit(warehouse)"
          :id="warehouse.nama.split(' ').join('_')"
        >
          <a href="#">{{ warehouse?.nama }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import { update_current_warehouse } from '@/utils/helpers';

export default {
  name: 'DropdownWarehouseComponent',
  data: function () {
    return {
      warehouses: [],
      selectedWarehouse: null,
    };
  },
  methods: {
    update_current_warehouse,
    ...mapMutations(['set_list_warehouses']),
    async getWarehouse() {
      try {
        this.isLoading = true;
        const req = await this.Axios.get(
          `${process.env.VUE_APP_API_URL}beranda/list-warehouse`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        );
        const res = req.data;
        this.isLoading = false;
        this.warehouses = res.data;
        this.selectedWarehouse = this.warehouses.find(
          (warehouse) => warehouse.selected
        );

        this.set_list_warehouses(res.data);
        this.update_current_warehouse(this.selectedWarehouse);
      } catch (e) {
        console.error(e);
        this.$toast.errror('Maaf terjadi kesalahan saat memuat data');
      }
    },
    async submit(warehouse) {
      try {
        this.isLoading = true;
        const data = new URLSearchParams();
        data.append('warehouse_id', warehouse.id);
        const req = await this.Axios.post(
          `${process.env.VUE_APP_API_URL}beranda/login-cabang`,
          data,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        );

        const res = req.data;
        this.isLoading = false;
        this.update_current_warehouse(warehouse);

        let isExpired = warehouse.selisih == 'Masa Aktif Berakhir';
        if (res.success === true && !isExpired) {
          window.location.href = '/';
        } else {
          window.location.href = '/informasi_akun/masa_aktif';
        }
      } catch (e) {
        this.isLoading = false;
        console.error(e);
      }
    },
  },
  created: function () {
    this.getWarehouse();
  },
};
</script>

<style scoped>
.dropdown-warehouse {
  padding: 8px 4px 8px 8px;
  gap: 8px;
  background: #ffffff;
  border: 1px solid #d4d4d8;
  border-radius: 10px;
}

.dropdown-warehouse .warehouse-item {
  padding: 10px;
  border-bottom: 1px solid #d4d4d8;
  text-wrap: wrap;
}

.dropdown-warehouse .warehouse-item:hover {
  cursor: pointer;
  background: #f2f2f7;
}

.dropdown-warehouse .warehouse-item:first-child:hover {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.dropdown-warehouse .warehouse-item:last-child {
  border-bottom: none;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.dropdown-warehouse .warehouse-item a {
  color: #000000;
  font-weight: 500;
  font-size: 14px;
}
</style>
