import Beep from '@/assets/audio/beep.mp3';
import dayjs from 'dayjs';
import 'dayjs/locale/id';

const calculate_check_digit = (code, weights) => {
  let sum = 0;
  for (let i = 0; i < code.length; i++) {
    let digit = parseInt(code[i]);
    sum += digit * weights[i % weights.length];
  }
  let checksum = sum % 10;
  return checksum.toString();
};

export const generate_code = (type) => {
  let result = '';
  let characters = '';
  let codeLength = 0;
  let checkDigitWeights = [];

  switch (type) {
    case 'CODE128':
      characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~';
      codeLength = 10;
      break;
    case 'CODE39':
      characters = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ-. $/+%';
      codeLength = 10;
      break;
    case 'EAN8':
      characters = '0123456789';
      codeLength = 7;
      checkDigitWeights = [3, 1, 3, 1, 3, 1, 3];
      break;
    case 'EAN13':
      characters = '0123456789';
      codeLength = 12;
      checkDigitWeights = [1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3];
      break;
    case 'UPC':
      characters = '0123456789';
      codeLength = 13;
      checkDigitWeights = [1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1];
      break;
    case 'ITF-14': // Tambahkan case untuk ITF-14
      characters = '0123456789';
      codeLength = 12;
      checkDigitWeights = [1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3];
      break;
    case 'Pharmacode': // Tambahkan case untuk Pharmacode
      characters = '0123456789';
      codeLength = 6;
      checkDigitWeights = [1, 3, 1, 3, 1, 3];
      break;
    case 'MSI10': // Tambahkan case untuk MSI10
      characters = '0123456789';
      codeLength = 4;
      checkDigitWeights = [2, 1, 2, 1];
      break;
    case 'MSI11': // Tambahkan case untuk MSI11
      characters = '0123456789';
      codeLength = 4;
      checkDigitWeights = [2, 1, 2, 1];
      break;
    case 'MSI1010': // Tambahkan case untuk MSI1010
      characters = '0123456789';
      codeLength = 6;
      checkDigitWeights = [2, 1, 2, 1, 2, 1];
      break;
    case 'MSI1110': // Tambahkan case untuk MSI1110
      characters = '0123456789';
      codeLength = 6;
      checkDigitWeights = [2, 1, 2, 1, 2, 1];
      break;
    case 'Codabar': // Tambahkan case untuk Codabar
      characters = '0123456789-$:/.+';
      codeLength = 10;
      checkDigitWeights = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1];
      break;
    // Add more cases for other barcode types here
    default:
      throw new Error('Unsupported barcode type');
  }

  for (let i = 0; i < codeLength - 1; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }

  // Check if checkDigitWeights is defined before calculating the check digit
  if (checkDigitWeights.length > 0) {
    let checkDigit = calculate_check_digit(result, checkDigitWeights);
    result += checkDigit;
  }

  return result;
};

export const generate_initial = (name) => {
  let splitName = name.split(' ');

  if (splitName.length === 1) {
    return splitName[0][0].toUpperCase();
  }

  let firstLetter = splitName[0][0];
  let secondLetter = '';

  for (let counter = 1; counter < splitName.length; counter++) {
    // ** Jika string kosong
    if (splitName[counter] === '') {
      let nextLetter = splitName[counter + 1];

      // ** Cek apakah splitName masih memiliki element berikutnya
      if (nextLetter !== undefined) {
        secondLetter = nextLetter[0];
        break;
      }
    } else {
      // ** Jika bukan string kosong
      secondLetter = splitName[counter][0];
      break;
    }
  }
  return `${firstLetter}${secondLetter}`.toUpperCase();
};

export const play_beep = () => {
  const audio = new Audio(Beep);
  audio.play();
};

export const convert_range = (array_date) => {
  let startDateObj = new Date(array_date[0]);
  let endDateObj = new Date(array_date[1]);

  let startDate = startDateObj.getDate().toString();
  let endDate = endDateObj.getDate().toString();
  let startMonth = (startDateObj.getMonth() + 1).toString();
  let endMonth = (endDateObj.getMonth() + 1).toString();

  return [
    `${startDateObj.getFullYear()}-${
      startMonth.length == 1 ? `0${startMonth}` : startMonth
    }-${startDate.length == 1 ? `0${startDate}` : startDate}`,
    `${endDateObj.getFullYear()}-${
      endMonth.length == 1 ? `0${endMonth}` : endMonth
    }-${endDate.length == 1 ? `0${endDate}` : endDate}`,
  ];
};

export const handle_nominal_input = (event) => {
  const value = event.target.value.replace(/[^0-9.]/g, '');

  return value.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

export const format_quantity = (value) => {
  if (!Number.isInteger(value)) {
    let isFloat = value % 1 !== 0;

    if (isFloat) {
      return String(value).replace('.', ',');
    } else {
      return Math.round(value);
    }
  }

  return value;
};

export const update_current_warehouse = (warehouse) => {
  let expiredDate;

  if (warehouse.selisih === 'Masa Aktif Berakhir') {
    expiredDate = null;
  } else {
    const matchRegex = warehouse.selisih.match(/\d+/);
    if (matchRegex) {
      let difference = parseInt(matchRegex[0]);

      let currentDate = new Date();
      currentDate.setDate(currentDate.getDate() + difference);
      expiredDate = dayjs(currentDate).format('DD MMMM YYYY');
    }
  }

  let currentWarehouse = {
    name: warehouse.nama,
    type: warehouse.tipe,
    expired_date: expiredDate,
  };

  localStorage.setItem('current_warehouse', JSON.stringify(currentWarehouse));
  localStorage.setItem('warehouse_id', warehouse.id);
};

export const redirect_subscription = (response, status) => {
  let isExpired = status == 'Masa Aktif Berakhir' || status < 0;

  if (response === true && isExpired) {
    window.location.href = '/informasi_akun/masa_aktif';
  } else {
    window.location.href = '/';
  }
};
